<template>
  <!-- LOGIN/PEDAGOGICO -->
  <v-app style="background-color: #dcebff">
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="4" class="mx-auto my-auto">
        <v-card
          class="
            px-10
            py-6
            text-center
            rounded-lg
            d-flex
            align-center
            justify-center
            flex-column
          "
          :height="$vuetify.breakpoint.xsOnly ? '100vh' : ''"
        >
          <!-- <div class="text-h5 font-weight-medium">Faça seu Login</div> -->

          <v-form ref="loginForm">
            <div class="my-4 text-center">
              <v-img
                src="../assets/Logo/SVG/LightBlack.svg"
                :width="$vuetify.breakpoint.xsOnly ? '250px' : '200px'"
                class="mx-auto mb-4 mt-n2 scale-up-center"
                :contain="$vuetify.breakpoint.xsOnly"
                @click="toHome()"
              ></v-img>
                {{ $t('login.login_inicio.inicio1') }}
              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ $t('login.login_inicio.inicio2') }}
              </span>
            </div>
            <v-select
              v-model="usertype"
              :items="positions"
              filled
              :label="$t('login.login_painel.entrar')"
              color="blue"
              dense
              item-color="darkblue"
            ></v-select>
            <v-text-field
              v-model="login.username"
              :label="$t('login.login_painel.login1')"
              color="blue"
              :rules="[rules.required]"
              dense
              filled
              :placeholder="$t('login.login_painel.login2')"
              @keyup.enter="tryLogin()"
              @click="incorrectUP = false"
            />
            <v-text-field
              v-model="login.password"
              :label="$t('login.login_painel.senha1')"
              color="blue"
              :rules="[rules.required, rules.length(4)]"
              dense
              filled
              :placeholder="$t('login.login_painel.senha2')"
              required
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="tryLogin()"
              @click="incorrectUP = false"
            />
            <v-alert
              v-if="incorrectUP"
              dense
              outlined
              text
              type="error"
              class="mt-n4"
              >{{ $t('login.login_painel.incorreta') }}</v-alert
            >
            <v-btn
              color="rgba(25, 124, 255, 0.25)"
              class="blue--text my-2"
              style="font-size: 18px"
              large
              block
              @click="tryLogin()"
            >
              {{ $t('login.login_painel.botao') }}
            </v-btn>
            <!-- <a href="" class="caption black--text">Esqueci a senha</a> -->
          </v-form>
        </v-card>
      </v-col>

      <v-col
        md="6"
        class="login-back elevation-24"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-img
          src="../assets/Dragon.svg"
          class="image-login mx-auto"
          width="65%"
        ></v-img>
      </v-col>
    </v-row>
    <v-tooltip bottom color="blue" v-if="$vuetify.breakpoint.mdAndUp">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          icon
          x-large
          fixed
          left
          v-bind="attrs"
          v-on="on"
          @click="toLogin()"
          class="blue--text"
          color="
            
              rgba(25, 124, 255, 0.25)
              
          "
          style="top: 16px"
        >
          <v-icon large> mdi-arrow-left </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('login.login_entraraluno') }}</span>
    </v-tooltip>
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      @click="toLogin()"
      class="blue--text text-caption font-weight-bold rounded-lg px-6"
      style="top: 20px; right: 15px"
      color="rgba(25, 124, 255, 0.20)"
    >
      {{ $t('login.login_entraraluno') }}
    </v-btn>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      login: {},
      positions: [
        { text: this.$t('login.login_usuario.usuario3'), value: "teacher" },
        { text: this.$t('login.login_usuario.usuario4'), value: "school" },
        { text: this.$t('login.login_usuario.usuario5'), value: "manager" },
        { text: this.$t('login.login_usuario.usuario6'), value: "client" },
      ],
      usertype: "teacher",
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      },
      showPassword: false,
      incorrectUP: false,
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toLogin() {
      this.$router.push("/login");
    },
    toTeacher() {
      let studentId = parseInt(this.$route.query.studentId);
      let params = (studentId > 0) ? "?studentId=" + studentId : "";
      this.$router.push("/teacher" + params);
      //this.$router.push("/teacherFuture" + params);
    },
    toTeacherF() {
      let studentId = parseInt(this.$route.query.studentId);
      let params = (studentId > 0) ? "?studentId=" + studentId : "";
      this.$router.push("/teacherFuture" + params);
    },
    toSchool() {
      this.$router.push("/school");
    },
    toManager() {
      this.$router.push("/manager");
    },
    toClient() {
      this.$router.push("/administrator");
    },
    async tryLogin() {
      let validation = this.$refs.loginForm.validate();
      if (validation) {
        if (this.usertype === "teacher") {
          try {
            let access = await axios.post(`${url}/login/teacher`, this.login);

            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            localStorage.setItem("accessToken", access.data.accessToken);

            //console.log(access.data.login.manual)
            // if(access.data.login.manual){
            //   this.toTeacherF();
            // }else{
            //   this.toTeacher();
            // }
            this.toTeacher();
          } catch (error) {
            console.error("ERROR", error);
          }
        }
        if (this.usertype === "school") {
          try {
            let access = await axios.post(`${url}/login/school`, this.login);
            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            await localStorage.setItem("accessToken", access.data.accessToken);

            this.toSchool();
          } catch (error) {
            console.error("ERROR", error);
          }
        }
        if (this.usertype === "manager") {
          try {
            let access = await axios.post(`${url}/login/manager`, this.login);
            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            localStorage.setItem("accessToken", access.data.accessToken);

            this.toManager();
          } catch (error) {
            console.error("ERROR", error);
          }
        }
        if (this.usertype === "client") {
          try {
            let access = await axios.post(`${url}/login/client`, this.login);
            if (access.data.status == "ERROR") return (this.incorrectUP = true);
            localStorage.setItem("accessToken", access.data.accessToken);

            this.toClient();
          } catch (error) {
            console.error("ERROR", error);
          }
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.login-back {
  background: linear-gradient(
    -45deg,
    #07629e,
    #0e86d4,
    #5561bb,
    rgb(91, 28, 153)
  );
  background-size: 300% 300%;
  border-bottom-left-radius: 300px;
  animation: gradient 4s linear infinite alternate;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-login {
  top: 10%;
  animation: bounce 5s alternate infinite;
}

@keyframes bounce {
  0%,
  30%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20%,
  80% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
